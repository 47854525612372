import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-layout',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  constructor(private toastr: ToastrService, private route: Router) {}

  ngOnInit(): void {}
  LogOut() {
    this.toastr.error('Logged Out !');
    this.route.navigate(['/admin-login']);
    localStorage.clear();
  }
  // sidebarToggle() {
  //   $('#sidebar').toggleClass('active-nav');
  //   $('#my-container').toggleClass('active-cont');
  // }
}
