import { environment } from './../../environments/environment';
import { Profile } from './../_models/profile';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
 


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}
  baseUrl: string = 'https://dryuva.kmatechnoware.com/api/MasterProfile/';


   getProfiles() { 
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
  
      
    return this.http.get<Profile[]>(this.baseUrl, {'headers': headers } );
  }
  getProfile(id: number) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
  
    return this.http.get<Profile>(this.baseUrl + id , {'headers': headers });
  }
  insertProfile(data: any) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('responseType', 'text');

  
    return this.http.post(this.baseUrl, data, {'headers': headers });
  }
  updateProfile(id: number, data: any) {
  const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('responseType', 'text');
    
    return this.http.put(this.baseUrl + id, data, { 'headers': headers  });
  }
  deleteProfile(id: number) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
  
    return this.http.delete(this.baseUrl + id, { 'headers': headers  });
  }
}
