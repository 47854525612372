import { ToastrService } from 'ngx-toastr';
import { Profile } from './../_models/profile';
import { ProfileService } from './../_services/profile.service';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeletePatientComponent } from './delete-patient/delete-patient.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
})
export class PatientComponent
  implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'mpCodeName',
    'mpName',
    'mpMobileNo',
    'mpCity',
    'action',
  ];
  PatientsData: Partial<Profile>[] = [];
  actionId = 0;

  dataSource: MatTableDataSource<Partial<Profile>>;
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService,
    private dailog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getPatients();
  }

  getPatients() {
    this.profileService.getProfiles().subscribe(
      (res: Profile[]) => {
        this.PatientsData = res.filter((v, i) => v.mCodeTypeId == 1);
        const patients = Array.from(
          { length: this.PatientsData.length },
          (_, k) => this.PatientsData[k]
        );
        this.dataSource = new MatTableDataSource(patients);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        this.toastr.error('Failed to Retrive Data!');
      }
    );
  }

  ngAfterViewInit() {
    const patients = Array.from(
      { length: this.PatientsData.length },
      (_, k) => this.PatientsData[k]
    );
    this.dataSource = new MatTableDataSource(patients);
  }
  ngAfterViewChecked(): void {}
  ngAfterContentInit(): void {
    this.dataSource = new MatTableDataSource(this.PatientsData);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id: number) {
    let dialog = this.dailog.open(DeletePatientComponent, {
      width: '600px',
      data: {
        id: id,
        type: 'profile',
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.getPatients();
    });
  }
}
