import { ToastrService } from 'ngx-toastr';
import { Profile } from './../../_models/profile';
import { ProfileService } from './../../_services/profile.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css'],
})
export class AddPatientComponent implements OnInit {
  staffData: Profile[];
  Patient: Partial<Profile> = {
    mpName: null,
    mpAge: null,
    mpGender: 'male',
    mpMobileNo: null,
    mpAlternateMobileNo: null,
    mpAddress: null,
    mpCity: null,
    mpIsActive: true,

    mCodeTypeId: 1,

    mtddate: null,
    mtdSitting: null,
    mtdSittingFees: null,
    mtdTreatment: null,
    mtdStaffId: null,
  };

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.getStaff();
  }
  OnSubmit() {
    this.Patient.mtddate.toLocaleString();
    console.log(this.Patient.mtdSittingFees);
    this.profileService.insertProfile(this.Patient).subscribe(
      (res) => {
        this.toastr.success('Patient Added Successfully!');
        this.router.navigate(['/patient']);
      },
      (error) => {
        this.toastr.error('Failed to add patient !');
      }
    );
  }

  getStaff() {
    this.profileService.getProfiles().subscribe((res: Profile[]) => {
      this.staffData = res.filter((v, i) => v.mCodeTypeId == 2);
    });
  }
}
