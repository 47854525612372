import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './../../_services/profile.service';
import { Profile } from './../../_models/profile';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css'],
})
export class AddStaffComponent implements OnInit {
  Staff: Partial<Profile> = {
    mpName: null,
    mpAge: null,
    mpGender: 'male',
    mpMobileNo: null,
    mpAlternateMobileNo: null,
    mpAddress: null,
    mpCity: null,
    mpIsActive: true,

    mCodeTypeId: 2,

    mtddate: new Date(),
    mtdSitting: '',
    mtdSittingFees: 0,
    mtdTreatment: '',
    mtdStaffId: 0,
  };
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  OnSubmit() {
    this.Staff.mtddate.toLocaleString();
    this.profileService.insertProfile(this.Staff).subscribe(
      (res) => {
        this.toastr.success('Staff Added Successfully!');
        this.router.navigate(['/staff']);
      },
      (error) => {
        this.toastr.error('Failed to add staff!');
      }
    );
  }
}

// "mCodeTypeId": 2,
// "mpAddress": "gsh",
// "mpAge": 45,
// "mpAlternateMobileNo": 456,
// "mpCity": "ghd",
// "mpGender": "male",
// "mpIsActive": true,
// "mpMobileNo": 5645,
// "mpName": "dfsa",
// "mtdSitting": "",
// "mtdSittingFees": 0,
// "mtdStaffId": 0,
// "mtdTreatment": "",
// "mtddate": ""
