<app-layout></app-layout>
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-9">
    <div class="p-1 my-container active-cont">
      <div
        class="container"
        style="background-color: aliceblue; border-radius: 15px"
      >
        <div class="text-center p-4">
          <p class="title" style="font-size: 60px; font-family: 'Poppins'">
            Add Staff
          </p>
        </div>
        <form #staffForm="ngForm">
          <!-- First Name -->
          <div class="form-group">
            <label for="mpName">First Name</label>
            <input
              type="text"
              name="mpName"
              [(ngModel)]="Staff.mpName"
              id="mpName"
              class="form-control"
              placeholder="Enter Name"
              required
            />
          </div>

          <!-- Age-->
          <div class="form-group">
            <label id="mpAge" for="mpAge">Age</label>
            <input
              type="number"
              name="mpAge"
              [(ngModel)]="Staff.mpAge"
              id="mpAge"
              class="form-control"
              placeholder="Age"
            />
          </div>
          <!-- Gender -->
          <div class="form-group">
            <p class="">Sex</p>
            <div class="mr-3 d-flex">
              <label class="m-3">
                <input
                  class="input-radio"
                  name="mpGender"
                  [(ngModel)]="Staff.mpGender"
                  type="radio"
                  value="male"
                  checked
                />Male</label
              >
              <label class="m-3">
                <input
                  name="mpGender"
                  [(ngModel)]="Staff.mpGender"
                  value="female"
                  type="radio"
                  class="input-radio"
                />Female</label
              >
            </div>
          </div>
          <!-- Mobile Number -->
          <div class="form-group">
            <label id="mpMobileNo" for="mpMobileNo">Mobile Number</label>
            <input
              type="number"
              name="mpMobileNo"
              [(ngModel)]="Staff.mpMobileNo"
              id="mpMobileNo"
              class="form-control"
              placeholder="Enter Mobile Number"
              required
            />
          </div>
          <!-- Alternate Number -->
          <div class="form-group">
            <label id="mpAlternateMobileNo" for="mpAlternateMobileNo"
              >Alternate Number</label
            >
            <input
              type="number"
              name="mpAlternateMobileNo"
              [(ngModel)]="Staff.mpAlternateMobileNo"
              id="mpAlternateMobileNo"
              class="form-control"
              placeholder="Enter Alternate Number"
              required
            />
          </div>

          <!-- Address -->
          <div class="form-group">
            <label id="mpAddress1" for="mpAddress1">Address</label>
            <input
              type="text"
              name="mpAddress1"
              [(ngModel)]="Staff.mpAddress"
              id="mpAddress1"
              class="form-control"
              placeholder="Enter Address"
              required
            />
          </div>
          <!-- City -->
          <div class="form-group">
            <label id="mpAddress2" for="mpAddress2">City</label>
            <input
              type="text"
              name="mpAddress2"
              [(ngModel)]="Staff.mpCity"
              id="mpAddress2"
              class="form-control"
              placeholder="Enter City"
              required
            />
          </div>

          <!-- Submit Button -->
          <div class="form-group text-center">
            <button type="submit" (click)="OnSubmit()" class="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
