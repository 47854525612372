import { Treatment } from './../_models/treatment';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TreatmentService {
  constructor(private http: HttpClient) {}
  baseUrl: string =
    'https://dryuva.kmatechnoware.com/api/MasterTreatmentDetail/';

  getTreatments(id: number) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

    return this.http.get<Treatment[]>(this.baseUrl+"GetAll/" + id , {'headers': headers });
  }
  getTreatment(id: number) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

    return this.http.get<Treatment>(this.baseUrl+"GetById/" + id , {'headers': headers });
  }
  insertTreatment(data: any) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('responseType', 'text');

    
    return this.http.post(this.baseUrl, data , {'headers': headers });
  }
  updateTreatment(id: number, data: any) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('responseType', 'text');
    
    return this.http.put(this.baseUrl + id, data, {'headers': headers });
  }
  deleteTreatment(id: number) {
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('responseType', 'text');
    
    return this.http.delete(this.baseUrl + id, {'headers': headers });
  }
}
