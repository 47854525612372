import { Profile } from './../../_models/profile';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './../../_services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-staff-detail',
  templateUrl: './staff-detail.component.html',
  styleUrls: ['./staff-detail.component.css'],
})
export class StaffDetailComponent implements OnInit {
  Staff: Partial<Profile> = {
    mpName: null,
    mpAge: null,
    mpGender: 'male',
    mpMobileNo: null,
    mpAlternateMobileNo: null,
    mpAddress: null,
    mpCity: null,
    mpIsActive: true,
  };

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getStaff();
  }
  getStaff() {
    this.profileService
      .getProfile(+this.route.snapshot.params['id'])
      .subscribe((res) => {
        this.Staff = res;
      });
  }

  OnUpdate() {
    this.profileService
      .updateProfile(+this.route.snapshot.params['id'], this.Staff)
      .subscribe(
        (res) => {
          this.toastr.success('Staff Details Updated !');
          this.router.navigate(['/staff']);
        },
        (error) => {
          this.toastr.error('Failed to update staff details!');
        }
      );
  }
}
