import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './../../_services/profile.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-staff',
  templateUrl: './delete-staff.component.html',
  styleUrls: ['./delete-staff.component.css'],
})
export class DeleteStaffComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
  OnDelete() {
    this.profileService.deleteProfile(this.data.id).subscribe(
      (res) => {
        this.toastr.success('Staff Deleted !');
      },
      (error) => {
        this.toastr.success('Failed to delete Staff!');
      }
    );
  }
}
