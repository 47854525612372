import { ProfileService } from './../_services/profile.service';
import { Profile } from './../_models/profile';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProfileResolver implements Resolve<Profile> {
  constructor(private profileService: ProfileService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.profileService.getProfiles().pipe(
      catchError((err) => {
        this.router.navigate(['/patient']);
        return of(null);
      })
    );
  }
}
