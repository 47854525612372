import { AuthGuard } from './_guards/auth.guard';
import { ProfileResolver } from './_resolvers/staff.resolver';
import { PatientDetailComponent } from './patient/patient-detail/patient-detail.component';
import { StaffDetailComponent } from './staff/staff-detail/staff-detail.component';
import { StaffComponent } from './staff/staff.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddPatientComponent } from './patient/add-patient/add-patient.component';
import { PatientComponent } from './patient/patient.component';

const routes: Routes = [
  { path: '', redirectTo: 'admin-login', pathMatch: 'full' },
  {
    path: 'patient',
    component: PatientComponent,
    resolve: { profile: ProfileResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'patient/add-patient',
    component: AddPatientComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patient/patient-detail/:id',
    component: PatientDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-patient',
    component: AddPatientComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'staff',
    component: StaffComponent,
    resolve: { profile: ProfileResolver },
  },
  {
    path: 'staff/add-staff',
    component: AddStaffComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'staff/staff-detail/:id',
    component: StaffDetailComponent,
    canActivate: [AuthGuard],
  },
  { path: 'admin-login', component: LoginComponent },
  { path: '**', redirectTo: 'patient', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
