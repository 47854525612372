<app-layout></app-layout>
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-9">
    <div class="p-1 my-container active-cont">
      <div
        class="container"
        style="background-color: aliceblue; border-radius: 15px"
      >
        <div class="text-center p-4">
          <p class="title" style="font-size: 60px; font-family: 'Poppins'">
            Patient Detail
          </p>
        </div>
        <div class="" id="print-section" hidden>
          <div class="row text-center text-capitalize">
            <div class="col-md-12 h1">Treatment Card</div>
          </div>
          <div class="row m-2 p-2">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>Name : {{ Patient.mpName | titlecase }}</th>
                  <th>Age : {{ Patient.mpAge }}</th>
                </tr>
                <tr>
                  <th>Gender : {{ Patient.mpGender | titlecase }}</th>
                  <th>Mobile No. : {{ Patient.mpMobileNo }}</th>
                </tr>
                <tr>
                  <th>Address : {{ Patient.mpAddress | titlecase }}</th>
                  <th>City : {{ Patient.mpCity | titlecase }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table text-center">
                <thead>
                  <th>Date</th>
                  <th>Sitting</th>
                  <th>Sitting Fees</th>
                  <th>Treatment</th>
                  <th>Done By</th>
                </thead>
                <tbody>
                  <tr *ngFor="let treatment of Treatments">
                    <td>{{ treatment.mtddate }}</td>
                    <td>{{ treatment.mtdSitting }}</td>
                    <td>Rs. {{ treatment.mtdSittingFees }}</td>
                    <td>{{ treatment.mtdTreatment }}</td>
                    <td>{{ treatment.mtdStaffName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-4 d-flex flex-end">
              <div class="col-md-12">Signature........</div>
            </div>
          </div>
        </div>

        <form id="survey-form">
          <div class="d-flex justify-content-end text-center">
            <i class="fa fa-share" style="margin-right: 15px"></i>
            <i
              class="fa fa-edit"
              (click)="updatePatientBtn()"
              style="margin-right: 15px"
            ></i>
            <button
              class="btn shadow-none p-0"
              printSectionId="print-section"
              ngxPrint
              [useExistingCss]="true"
            >
              <i class="fa fa-print"></i>
            </button>
          </div>
          <!-- First Name -->
          <div class="form-group">
            <label id="mpFirstName" for="mpName">Name</label>
            <input
              type="text"
              name="mpName"
              [(ngModel)]="Patient.mpName"
              id="mpFirstName"
              class="form-control"
              placeholder="Enter Name"
              required
              [disabled]="!updateProfile"
            />
          </div>

          <!-- Age -->
          <div class="form-group">
            <label id="mpAge" for="mpAge">Age</label>
            <input
              type="number"
              name="mpAge"
              [(ngModel)]="Patient.mpAge"
              id="mpAge"
              class="form-control"
              placeholder="Age"
              [disabled]="!updateProfile"
            />
          </div>
          <!-- Gender -->
          <div class="form-group">
            <p class="">Sex</p>
            <div class="mr-3 d-flex">
              <label class="m-3">
                <input
                  class="input-radio"
                  name="mpGender"
                  [(ngModel)]="Patient.mpGender"
                  type="radio"
                  value="male"
                  [disabled]="!updateProfile"
                />Male</label
              >
              <label class="m-3">
                <input
                  name="mpGender"
                  [(ngModel)]="Patient.mpGender"
                  value="female"
                  type="radio"
                  class="input-radio"
                  [disabled]="!updateProfile"
                />Female</label
              >
            </div>
          </div>
          <!-- Mobile Number -->
          <div class="form-group">
            <label id="mpMobileNo" for="mpMobileNo">Mobile Number</label>
            <input
              type="number"
              name="mpMobileNo"
              [(ngModel)]="Patient.mpMobileNo"
              id="mpMobileNo"
              class="form-control"
              placeholder="Enter Mobile Number"
              required
              [disabled]="!updateProfile"
            />
          </div>
          <!-- Alternate Number -->
          <div class="form-group">
            <label id="mpAlternateMobileNo" for="mpAlternateMobileNo"
              >Alternate Number</label
            >
            <input
              type="number"
              name="mpAlternateMobileNo"
              [(ngModel)]="Patient.mpAlternateMobileNo"
              id="mpAlternateMobileNo"
              class="form-control"
              placeholder="Enter Alternate Number"
              required
              [disabled]="!updateProfile"
            />
          </div>

          <!-- Address -->
          <div class="form-group">
            <label id="mpAddress1" for="mpAddress1">Address</label>
            <input
              type="text"
              name="mpAddress1"
              [(ngModel)]="Patient.mpAddress"
              id="mpAddress1"
              class="form-control"
              placeholder="Enter Address"
              required
              [disabled]="!updateProfile"
            />
          </div>

          <!-- City -->
          <div class="form-group">
            <label id="mpAddress2" for="mpAddress2">City</label>
            <input
              type="text"
              name="mpAddress2"
              [(ngModel)]="Patient.mpCity"
              id="mpAddress2"
              class="form-control"
              placeholder="Enter City"
              required
              [disabled]="!updateProfile"
            />
          </div>
          <div class="text-center">
            <button
              class="btn btn-danger my-3"
              *ngIf="updateProfile"
              (click)="updatePatient()"
            >
              Update Profile
            </button>
          </div>

          <div class="updateTable mt-4">
            <table class="table text-center">
              <thead>
                <th>Date</th>
                <th>Sitting</th>
                <th>Sitting Fees</th>
                <th>Treatment</th>
                <th>Done By</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr *ngFor="let treatment of Treatments">
                  <td>{{ treatment.mtddate }}</td>
                  <td>{{ treatment.mtdSitting }}</td>
                  <td>{{ treatment.mtdSittingFees }}</td>
                  <td>{{ treatment.mtdTreatment }}</td>
                  <td>{{ treatment.mtdStaffName }}</td>
                  <td>
                    <i
                      class="fa fa-edit"
                      style="margin-right: 10px"
                      (click)="getTreatment(treatment.mtdId)"
                    ></i>
                    <i
                      class="fa fa-trash"
                      (click)="deleteTreatment(treatment.mtdId)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-success mt-2" (click)="addNew('addNew')">
              Add New
            </button>
            <div class="treatmentAdd mt-3" *ngIf="treatnemtAdd">
              <div class="row">
                <!-- Date -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="mtddate">Date</label>
                    <input
                      type="date"
                      name="mtddate"
                      [(ngModel)]="Treatment.mtddate"
                      id="mtddate"
                      class="form-control"
                      placeholder="Date"
                      required
                    />
                  </div>
                </div>
                <!-- Sitting -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label id="mtdSitting" for="mtdSitting">Sitting</label>
                    <input
                      type="text"
                      name="mtdSitting"
                      [(ngModel)]="Treatment.mtdSitting"
                      id="mtdSitting"
                      class="form-control"
                      placeholder="Sitting"
                      required
                    />
                  </div>
                </div>
                <!-- Sitting Fees -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label id="mtdSittingFees" for="mtdSittingFees"
                      >Sitting Fees</label
                    >
                    <input
                      type="number"
                      name="mtdSittingFees"
                      [(ngModel)]="Treatment.mtdSittingFees"
                      id="mtdSittingFees"
                      class="form-control"
                      placeholder="Sitting Fees"
                      required
                    />
                  </div>
                </div>
                <!-- Treatment -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label id="mtdTreatment" for="mtdTreatment"
                      >Treatment</label
                    >
                    <input
                      type="text"
                      name="mtdTreatment"
                      [(ngModel)]="Treatment.mtdTreatment"
                      id="mtdTreatment"
                      class="form-control"
                      placeholder="Treatment"
                      required
                    />
                  </div>
                </div>
                <!-- Done By -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label id="mtdTreatment" for="mtdTreatment">Done By</label>
                    <select
                      class="form-control"
                      name="mtdStaffId"
                      [(ngModel)]="Treatment.mtdStaffId"
                    >
                      <option
                        *ngFor="let staff of staffData"
                        [ngValue]="staff.mpId"
                      >
                        {{ staff.mpName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group text-center">
                <button
                  *ngIf="treatnemtAdd"
                  type="submit"
                  id="submit"
                  (click)="insertTreatment()"
                  class="btn btn-primary"
                >
                  Add Treatment
                </button>
                <button
                  *ngIf="treatmentUpdate"
                  type="submit"
                  id="submit"
                  (click)="updateTreatment()"
                  class="btn btn-primary"
                >
                  Update Treatment
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
