import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './../_services/profile.service';

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Profile } from '../_models/profile';
import { Router } from '@angular/router';
import { DeleteStaffComponent } from './delete-staff/delete-staff.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css'],
})
export class StaffComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'mpCodeName',
    'mpName',
    'mpMobileNo',
    'mpCity',
    'action',
  ];
  staffData: Partial<Profile>[] = [];
  dataSource: MatTableDataSource<Partial<Profile>>;
  actionId = 0;
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService,
    private dailog: MatDialog
  ) {}
  ngOnInit(): void {
    this.getStaff();
  }
  getStaff() {
    this.profileService.getProfiles().subscribe(
      (res: Profile[]) => {
        this.staffData = res.filter((v, i) => v.mCodeTypeId == 2);
        const staff = Array.from(
          { length: this.staffData.length },
          (_, k) => this.staffData[k]
        );
        this.dataSource = new MatTableDataSource(staff);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        this.toastr.error('Failed to Retrive Data!');
      }
    );
  }

  ngAfterViewInit() {
    const patients = Array.from(
      { length: this.staffData.length },
      (_, k) => this.staffData[k]
    );
    this.dataSource = new MatTableDataSource(patients);
  }
  ngAfterContentInit(): void {
    this.dataSource = new MatTableDataSource(this.staffData);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  delete(id: number) {
    let dialog = this.dailog.open(DeleteStaffComponent, {
      width: '600px',
      data: {
        id: id,
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.getStaff();
    });
  }
}
