import { TreatmentService } from './../../_services/treatment.service';
import { ProfileService } from './../../_services/profile.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-patient',
  templateUrl: './delete-patient.component.html',
  styleUrls: ['./delete-patient.component.css'],
})
export class DeletePatientComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private treatmentService: TreatmentService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {}
  OnDelete() {
    if (this.data.type == 'profile') {
      this.profileService.deleteProfile(this.data.id).subscribe(
        (res) => {
          this.toastr.success('Patient Deleted !');
        },
        (error) => {
          this.toastr.success('Failed to delete patient!');
        }
      );
    }
    if (this.data.type == 'treatment') {
      this.treatmentService.deleteTreatment(this.data.id).subscribe(
        (res) => {
          this.toastr.success('Treatment Deleted !');
        },
        (error) => {
          this.toastr.success('Failed to delete treatment!');
        }
      );
    }
  }
}
