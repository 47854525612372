import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private toastr: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const status = localStorage.getItem('status');
    if (status == '1') {
      return true;
    } else {
      this.router.navigate(['/admin-login']);
      this.toastr.error('You must login first!');
      return false;
    }
  }
}
