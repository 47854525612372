<div class="scene flex">
  <section class="card">
    <figure class="card__figure">
      <img
        class="card__image"
        src="https://picsum.photos/id/454/200/200"
        alt="A female hand holding a camera"
      />
      <figcaption>
        <span class="visually-hidden"> Avatar photo </span>
      </figcaption>
    </figure>
    <h1 class="card__heading">
      <span class="visually-hidden">Account name:</span>
      <span> Hey, Admin </span>
    </h1>
    <form class="card__form">
      <label for="username" class="visually-hidden">Username :</label>
      <input id="username" class="card__input" type="text" />
      <label for="password" class="visually-hidden">Password :</label>
      <input
        id="password"
        class="card__input"
        type="password"
        style="margin-top: 15px"
      />
      <button class="card__button" type="button" (click)="OnLogIn()">
        <span>Login</span>
      </button>
    </form>
  </section>
</div>
