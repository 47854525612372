<div class="col-md-3 position-fixed top-0 left-0 h-100">
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- Side-Nav -->
      <div
        class="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column"
        id="sidebar"
      >
        <ul class="nav flex-column text-white w-100 text-center">
          <div class="nav-link h3 text-white my-2">
            Yuva &nbsp;Skin &nbsp;Clinic
          </div>
          <li href="#" class="nav-link mt-3">
            <i class="bx bxs-dashboard"></i>
            <span
              class="mx-2 navLink"
              routerLinkActive="active"
              [routerLink]="['/staff']"
              >Staff</span
            >
          </li>
          <li href="#" class="nav-link mt-3">
            <i class="bx bxs-dashboard"></i>
            <span
              class="mx-2 navLink"
              routerLinkActive="active"
              [routerLink]="['/patient']"
              >Patient</span
            >
          </li>
          <li href="#" class="nav-link mt-3">
            <i class="bx bxs-dashboard"></i>
            <span class="mx-2 navLink" (click)="LogOut()">Log Out</span>
          </li>
        </ul>
      </div>

      <!-- Main Wrapper -->
      <!-- <div class="p-1 my-container active-cont">
      
        <nav class="navbar top-navbar navbar-light px-5 w-75">
          <a class="btn border-0" id="menu-btn" (click)="sidebarToggle()"
            ><i class="bx bx-menu"></i
          ></a>
        </nav>
      </div> -->
    </div>
  </div>
</div>
