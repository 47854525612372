import { MatDialog } from '@angular/material/dialog';
import { Treatment } from './../../_models/treatment';
import { TreatmentService } from './../../_services/treatment.service';
import { ToastrService } from 'ngx-toastr';
import { Profile } from './../../_models/profile';
import { ProfileService } from './../../_services/profile.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeletePatientComponent } from '../delete-patient/delete-patient.component';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css'],
})
export class PatientDetailComponent implements OnInit {
  staffData: Profile[];
  mCode = 0;
  treatnemtAdd: boolean = false;
  treatmentUpdate: boolean = false;
  updateProfile: boolean = false;

  Patient: Partial<Profile> = {
    mpName: null,
    mpAge: null,
    mpGender: 'male',
    mpMobileNo: null,
    mpAlternateMobileNo: null,
    mpAddress: null,
    mpCity: null,
    mpIsActive: true,
    mCodeTypeId: 1,
  };

  Treatments: Treatment[];
  Treatment: Partial<Treatment> = {
    mtdCodeId: null,
    mtddate: null,
    mtdSitting: null,
    mtdSittingFees: null,
    mtdTreatment: null,
    mtdStaffId: null,
  };

  empty() {
    this.Treatment = {
      mtdCodeId: null,
      mtddate: null,
      mtdSitting: null,
      mtdSittingFees: null,
      mtdTreatment: null,
      mtdStaffId: null,
    };
  }
  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private treatmentService: TreatmentService,
    private toastr: ToastrService,
    private router: Router,
    private dailog: MatDialog
  ) {}

  ngOnInit() {
    this.getPatient();
    this.getStaff();
  }

  addNew(type: string) {
    if (type == 'addNew') {
      this.treatnemtAdd = !this.treatnemtAdd;
    }
    if (type == 'update') {
      this.treatmentUpdate = !this.treatmentUpdate;
    }
  }

  updatePatientBtn() {
    this.updateProfile = !this.updateProfile;
  }

  getPatient() {
    this.profileService.getProfile(+this.route.snapshot.params['id']).subscribe(
      (res: any) => {
        this.Patient = res;
        this.mCode = +res.mpCodeId;
        this.getTreatments(+res.mpCodeId);
      },
      (error) => {
        this.toastr.error('Failed to get patient details!');
      }
    );
  }

  updatePatient() {
    this.profileService
      .updateProfile(+this.route.snapshot.params['id'], this.Patient)
      .subscribe(
        (res) => {
          this.toastr.success('Profile Updated!');
        },
        (err) => {
          this.toastr.error('Failed to Update Profile!');
        }
      );
  }

  getStaff() {
    this.profileService.getProfiles().subscribe(
      (res: Profile[]) => {
        this.staffData = res.filter((v, i) => v.mCodeTypeId == 2);
      },
      (err) => {
        this.toastr.error('Failed to get staff!');
      }
    );
  }

  getTreatment(id: number) {
    this.addNew('update');
    this.treatmentService.getTreatment(id).subscribe(
      (res) => {
        this.Treatment = res;
      },
      (error) => {
        this.toastr.error('Failed to retrive treatment!');
      }
    );
  }

  getTreatments(id: number) {
    this.treatmentService.getTreatments(id).subscribe(
      (res: Treatment[]) => {
        this.Treatments = res;
        console.log(res);
      },
      (error) => {
        this.toastr.error('Failed to get treatments!');
      }
    );
  }

  insertTreatment() {
    this.Treatment.mtddate.toLocaleString();
    this.Treatment.mtdCodeId = this.mCode;

    this.treatmentService.insertTreatment(this.Treatment).subscribe(
      (res) => {
        this.toastr.success('Treatment Added!');
        this.getTreatments(this.mCode);
        this.empty();
        this.treatnemtAdd = false;
      },
      (error) => {
        this.toastr.error('Failed to add treatment!');
      }
    );
  }

  updateTreatment() {
    this.treatmentService
      .updateTreatment(this.Treatment.mtdId, this.Treatment)
      .subscribe(
        (res) => {
          this.toastr.success('Treatment Updated!');
          this.getTreatments(this.mCode);
          this.empty();
          this.treatmentUpdate = false;
        },
        (error) => {
          this.toastr.error('Failed to update treatment!');
        }
      );
  }

  deleteTreatment(id: number) {
    let dialog = this.dailog.open(DeletePatientComponent, {
      width: '600px',
      data: {
        id: id,
        type: 'treatment',
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.getPatient();
    });
  }
}
