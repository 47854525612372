<app-layout></app-layout>
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-9">
    <p class="display-1 text-center">Patients List</p>

    <button class="btn btn-danger btn-lg" [routerLink]="['./add-patient']">
      Add Patient
    </button>

    <div class="patientTable">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          placeholder="Ex. XYZ"
          #input
        />
      </mat-form-field>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="mpCodeName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Serial No.
            </th>
            <td mat-cell *matCellDef="let row">{{ row.mpCodeName }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="mpName">
            <th mat-header-cell *matHeaderCellDef>Patient Name</th>
            <td mat-cell *matCellDef="let row">{{ row.mpName }}</td>
          </ng-container>

          <!-- Mobile No Column -->
          <ng-container matColumnDef="mpMobileNo">
            <th mat-header-cell *matHeaderCellDef>Mobile No</th>
            <td mat-cell *matCellDef="let row">{{ row.mpMobileNo }}</td>
          </ng-container>
          <!-- City Column -->
          <ng-container matColumnDef="mpCity">
            <th mat-header-cell *matHeaderCellDef>City</th>
            <td mat-cell *matCellDef="let row">{{ row?.mpCity }}</td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <i
                class="fa fa-edit"
                style="margin-right: 15px; cursor: pointer"
                [routerLink]="['./patient-detail/', row.mpId]"
              ></i>

              <i
                data-toggle="modal"
                data-target="#exampleModal"
                class="fa fa-trash"
                style="margin-right: 15px; cursor: pointer"
                (click)="delete(row.mpId)"
              ></i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
