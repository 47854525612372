import { DeletePatientComponent } from './patient/delete-patient/delete-patient.component';
import { DeleteStaffComponent } from './staff/delete-staff/delete-staff.component';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptorService } from './_interceptor/loader-intercepter.service';
import { PatientDetailComponent } from './patient/patient-detail/patient-detail.component';
import { StaffDetailComponent } from './staff/staff-detail/staff-detail.component';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AddPatientComponent } from './patient/add-patient/add-patient.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { StaffComponent } from './staff/staff.component';
import { PatientComponent } from './patient/patient.component';
import { LoaderComponent } from './loader/loader.component';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    ToastrModule.forRoot(),
    NgxPrintModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    PatientComponent,
    AddPatientComponent,
    DeletePatientComponent,
    PatientDetailComponent,
    StaffComponent,
    AddStaffComponent,
    DeleteStaffComponent,
    StaffDetailComponent,
    LoaderComponent,
  ],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
